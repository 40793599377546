import React from 'react';
import Tick from '../../images/misc/brand-tick.svg';
import './styles.scss';
import { openURL } from '../../utils';

const BrandInfoCard = () => {
  const handleBtnClick = () => {
    openURL('brand_app_calc');
  };
  return (
    <div className="brand-info-card__container container">
      <div className="brand-info-card">
        <div className="title-container d-none d-md-flex">
          <h6>Not sure? Get an estimated funding amount for your brand.</h6>
          <button
            type="button"
            className="btn btnklub1"
            onClick={handleBtnClick}
          >
            Start now
          </button>
        </div>
        <div className="title-container d-flex d-md-none">
          <h6>
            Klub&apos;s RBF is a no-brainer for you! You can use it to generate.
          </h6>
        </div>
        <div className="points-container">
          <ul>
            <li>
              <img src={Tick} alt="tick" />
              {' '}
              Answer few questions about your
              business
            </li>
            <li>
              <img src={Tick} alt="tick" />
              {' '}
              Get an estimate within seconds
            </li>
            <li>
              <img src={Tick} alt="tick" />
              {' '}
              Simple, safe and secure
            </li>
          </ul>
          <button
            type="button"
            className="btn btnklub1 d-flex d-md-none"
            onClick={handleBtnClick}
          >
            Get an estimate now
          </button>
        </div>
      </div>
    </div>
  );
};

export default BrandInfoCard;
