/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-filename-extension */
import React, { useState, useEffect } from 'react';
import { isString } from 'lodash';
// import { useNavigate } from '@reach/router';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import faqdata from '../../mockdata/faqcontent';

import {
  ProcessSection,
  AdvantageCard,
  FeaturesCard
} from '../../components/brands';

import BannerSection from '../../components/banner';

import seamless from '../../../static/brands/svg/seamless.svg';
import flexible from '../../../static/brands/png/flexible.png';
import product from '../../../static/brands/png/product.png';
import capital from '../../../static/brands/svg/capital.svg';

import seamlessbanner from '../../../static/brands/patterns/png/square.png';

import apply from '../../../static/brands/svg/apply.svg';
import assessment from '../../../static/brands/svg/assessment.svg';
import repayment from '../../../static/brands/svg/repayment.svg';
// import logo from '../../../static/brands/svg/logo.svg';

import blaze from '../../../static/brands/svg/Fire.svg';
// import speed from '../../../static/brands/svg/Speed.svg';
import gro from '../../../static/brands/svg/grow.svg';
import funding from '../../../static/brands/svg/funding.svg';
// import groupStock from '../../../static/brands/svg/Group.svg';
// import deerStock from '../../../static/brands/svg/Deer.svg';
// import inMobi from '../../../static/brands/png/inmobi.png';
// import sokrati from '../../../static/brands/png/sokrati.png';
import { Carousal } from '../../components/landing';

import style from '../styles/brands.module.scss';
import brandBanner from '../../../static/banners/brand.svg';
import { videoList } from '../../mockdata/video';
import Styles from '../styles/landingPage.module.scss';
import { Accordian } from '../../components/faq';
import SearchIcon from '../../../static/klub-usp/svg/Search.svg';
import faqstyle from '../styles/faq.module.scss';
import { openURL, saveURLParamToSession } from '../../utils';
import TestimonialCard from '../../components/testimonialCard';
import Slider from '../../components/slider';
import BrandInfoCard from '../../components/cards/brandInfoCard';

// const advantagesSpeed = [
//   <p>3 to 6 months tenure</p>,
//   <p>2-15% revenue share</p>,
//   <p>Offered as Klub’s digital credit card for ad-spends </p>,
//   <p>
//     No hidden charges, repay 1.06x - 1.1x of funding amount, all-inclusive
//   </p>
// ];

const advantagesBlaze = [
  <p>3 to 6 months tenure</p>,
  <p>Flexible repayments as a percentage of revenues</p>,
  <p>Offered in partnership with NBFCs </p>,
  <p>
    No hidden charges, repay 1.06x - 1.1x of funding amount, all-inclusive
  </p>
];

const advantagesGro = [
  <p>12 to 18 months tenure</p>,
  // <p>Monthly payments as 1-10% revenue shares</p>,
  <p>1-10% revenue share</p>,
  <p>Funded by Klub’s patron investor network </p>,
  <p>
    No hidden charges, repay 1.17x - 1.2x of funding amount, all-inclusive
  </p>
];

const features = [
  {
    icon: flexible,
    title: 'Flexible repayments',
    content: 'Repay as a % of your revenues',
    banner: seamlessbanner
  },
  {
    icon: seamless,
    title: 'Transparent pricing',
    content: '100% clarity on total amount to be repaid'
  },
  {
    icon: capital,
    title: 'Capital without constraints',
    content: 'No equity dilution, retain complete control'
  },
  {
    icon: product,
    title: 'Seamless and easy',
    content: 'End-to-end support by our in-house experts'
  }
];

//

const leftProcesses = [
  {
    icon: apply,
    title: 'Apply',
    content: 'Share your business and financial data securely'
  },
  {
    icon: funding,
    title: 'Funding',
    content: 'Confirm commercials and sign documentation to access financing '
  }
];

const rightProcesses = [
  {
    icon: assessment,
    title: 'Assessment',
    content:
      'Our underwriting engine evaluates your brand and creates a term sheet'
  },
  {
    icon: repayment,
    title: 'Repayment',
    content: 'Pay via easy revenue shares, till your flat fee is paid off  '
  }
];

const processes = [
  {
    icon: apply,
    title: 'Apply',
    content: 'Share your business and financial data securely'
  },
  {
    icon: assessment,
    title: 'Assessment',
    content:
      'Our underwriting engine evaluates your brand and creates a term sheet'
  },
  {
    icon: funding,
    title: 'Funding',
    content: 'Confirm commercials and sign documentation to access financing '
  },
  {
    icon: repayment,
    title: 'Repayment',
    content: 'Pay via easy revenue shares, till your flat fee is paid off '
  }
];

const BrandPage = () => {
  // const navigate = useNavigate();
  const [advantageTab, setAdvantageTab] = useState(0);
  const [selectedQuestionId, setSelectedQuestionId] = useState(null);
  const [searchWord, setSearchWord] = useState('');
  const [searchResults, setSearchResults] = useState(faqdata.faqbrands);
  const [prevSearchWord, setPrevSearchWord] = useState('');

  useEffect(() => {
    const hashValue = window
      && window.location
      && window.location.hash
      && window.location.hash.slice(1);
    if (hashValue) {
      const section = window && window.document && window.document.getElementById(hashValue);
      const headerOffset = 30;
      const elementPosition = section && section.getBoundingClientRect().top + window.scrollY;
      const offsetPosition = elementPosition - headerOffset;
      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth'
      });
    }
  }, []);

  const handleChangeInput = (event) => {
    const { value } = event.target;
    if (searchWord !== value) {
      setSearchWord(value.trim());
      if (value === '') {
        onSearchClick();
      }
    }
  };

  const onSearchClick = (e) => {
    if (e) e.preventDefault();
    setPrevSearchWord(searchWord);
    const results = [];
    // eslint-disable-next-line consistent-return
    faqdata.faqbrands.forEach((item) => {
      if (
        isString(item.question)
        && item.question.toUpperCase().includes(searchWord.toUpperCase())
      ) {
        return results.push(item);
      }
    });
    setSearchResults(results);
  };

  const handleBrandNavigate = (type = 'signup') => {
    switch (type) {
      case 'calc':
        openURL('brand_app_calc');
        break;
      case 'signup':
        openURL('brand_app_signup');
        break;
      default:
        break;
    }
  };

  // const handleFaqScroll = () => {
  //   navigate('/brands#faq');
  //   const faq = document.getElementById('faq');
  //   if (faq) {
  //     faq.scrollIntoView({ behavior: 'smooth' });
  //   }
  // };

  useEffect(() => {
    saveURLParamToSession();
  }, []);

  return (
    <Layout withCampaign={false}>
      <SEO
        title="Get instant funding for your growing brand"
        description="Klub offers quick and easy financing for entrepreneurs with zero equity dilution and flexible repayments."
      />
      <BannerSection
        image={brandBanner}
        heading="Focus on your business, "
        subHeading="not on financing"
        description="Get Scalable and Non-Dilutive Revenue Based Financing"
        buttons={[
          <button
            type="button"
            onClick={() => handleBrandNavigate('signup')}
            className="btn btnklub1"
          >
            Apply Now
          </button>
        ]}
      />

      <div className={style.brandSection1}>
        <div className="container">
          <div className={style.section}>
            <h3 className={style.sectionHeader}>WHY BRANDS LOVE KLUB</h3>
            <h1 className={style.content}>
              Skin-in-the-game financing
              <br />
              for entrepreneurs, by entrepreneurs
            </h1>
          </div>
        </div>
        <div className="container">
          <div className={style.carousalWeb}>
            {features.map((item) => (
              <FeaturesCard
                content={item.content}
                title={item.title}
                icon={item.icon}
              />
            ))}
          </div>
        </div>
        <div className={style.carousalMob}>
          <Carousal name="brandFeatures" margin={true} autoScroll={false}>
            {features.map((item) => (
              <FeaturesCard
                content={item.content}
                title={item.title}
                icon={item.icon}
              />
            ))}
          </Carousal>
        </div>
        <div className={style.advantageButtonWrapper}>
          {/* <Link to="/brands-contact" className="btn btnklub3">Get funded</Link> */}
          <button
            type="button"
            onClick={() => handleBrandNavigate('signup')}
            className="btn btnklub3"
          >
            Apply Now
          </button>
        </div>
      </div>

      <div className={style.klubAdvantageSection} id="klub-advantage">
        <div className="container">
          <div className={style.section}>
            <h3 className={style.sectionHeader}>THE KLUB ADVANTAGE</h3>
            <h5 className={style.sectionHeaderh1}>Innovative financing products for the new economy</h5>
            {/* desktop view */}
            <div className={style.advantageCardWrapper}>
              {/* <div className={style.blazeWrapper}>
                <div className={`${style.advantagesCard} advantagesCard`}>
                  <div className={style.cardContent}>
                    <div className={style.cardIcon}>
                      <img
                        src={speed}
                        className={style.advantageTopIcon}
                        alt=""
                      />
                      <h4 className={style.cardHeading}>Klub Speed</h4>
                    </div>
                    <AdvantageCard
                      contentHeading="Digital marketing financing"
                      content={advantagesSpeed}
                      amount="₹2 Lakhs - ₹10 Lakhs"
                    />
                  </div>
                </div>
              </div> */}
              <div className={style.blazeWrapper}>
                <div className={`${style.advantagesCard} advantagesCard`}>
                  <div className={style.cardContent}>
                    <div className={style.cardIcon}>
                      <img
                        src={blaze}
                        className={style.advantageTopIcon}
                        alt=""
                      />
                      <h4 className={style.cardHeading}>Klub Blaze</h4>
                    </div>
                    <AdvantageCard
                      contentHeading="Inventory & marketing financing"
                      content={advantagesBlaze}
                      amount="₹10 Lakhs - ₹25 Lakhs"
                    />
                  </div>
                </div>
              </div>
              <div className={style.groWrapper}>
                <div className={`${style.advantagesCard} advantagesCard`}>
                  <div className={style.cardContent}>
                    <div className={style.cardIcon}>
                      <img
                        src={gro}
                        className={style.advantageTopIcon}
                        alt=""
                      />
                      <h4 className={style.cardHeading}>Klub Gro</h4>
                    </div>
                    <AdvantageCard
                      contentHeading={`Growth capital `}
                      content={advantagesGro}
                      amount="₹25 Lakhs - ₹5 Crores"
                    />
                  </div>
                </div>
              </div>
            </div>
            {/* mobile view */}
            <div className={style.advantageTabWrapper}>
              <div className={style.tabWrapper}>
                <div
                  className={
                    advantageTab === 0
                      ? style.selectedAdvantageTab
                      : style.advantageTab
                  }
                  role="presentation"
                  onClick={() => setAdvantageTab(0)}
                >
                  <div className={style.cardIcon}>
                    <img
                      src={blaze}
                      className={style.advantageTopIcon}
                      alt=""
                    />
                  </div>
                  <h4>Klub Blaze</h4>
                </div>
                <div
                  className={
                    advantageTab === 1
                      ? style.selectedAdvantageTab
                      : style.advantageTab
                  }
                  role="presentation"
                  onClick={() => setAdvantageTab(1)}
                >
                  <div className={style.cardIcon}>
                    <img
                      src={gro}
                      loading="lazy"
                      className={style.advantageTopIcon}
                      alt=""
                    />
                  </div>
                  <h4>Klub Gro</h4>
                </div>
              </div>
              <AdvantageCard
                contentHeading={
                  advantageTab === 0 ? (
                    <p>
                      <span className={style.subText}>
                        Finance your advertising and inventory spends with ease
                      </span>
                    </p>
                  ) : (
                    <p>
                      <span className={style.subText}>
                        Growth capital for inventory and for business expansion
                        needs.
                      </span>
                    </p>
                  )
                }
                content={advantageTab === 0 ? advantagesBlaze : advantagesGro}
              />
            </div>
            <div className={style.advantageButtonWrapper}>
              <button
                type="button"
                onClick={() => handleBrandNavigate('signup')}
                className="btn btnklub3 ml3"
              >
                Apply Now
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* Video Testimonial */}
      <div className={`${Styles.sectionWrapper} ${Styles.homeSection2}`}>
        <div className={`${Styles.sectionContentBox} container`}>
          <h3>TESTIMONIALS</h3>
          <h5>Hear from our brands</h5>
        </div>
        <div
          className={`${Styles.videocardSection} videoCardContainer container`}
        >
          <Slider
            settings={{
              slidesToShow:
                typeof window !== 'undefined' && window?.innerWidth > 768
                  ? 2
                  : 1
            }}
            name="video-testimonial-about"
          >
            {videoList.home
              .filter((item) => item.isBrand)
              .map((item) => (
                <TestimonialCard
                  key={item.id}
                  img={item.imgSrc}
                  head={item.head}
                  name={item.name}
                  profile={item.profile}
                  url={item.url}
                  dp={item.dp}
                  webp={item.webpSrc}
                  isBrand={true}
                  dpWebp={item.dpWebp}
                  isHomepage={true}
                  customMargin={item.customMargin}
                />
              ))}
          </Slider>
        </div>
      </div>
      <div className={style.brandSection2}>
        <div className="container">
          <div className={style.section}>
            <h3 className={style.sectionHeader}>HOW THE PROCESS WORKS</h3>
            <h5 className={style.content}>
              Easy financing delivered
              {' '}
              <br />
              {' '}
              to you seamlessly
            </h5>
          </div>
          <ProcessSection
            name="brands"
            leftProcesses={leftProcesses}
            rightProcesses={rightProcesses}
            processes={processes}
          />
          <div className={style.processButtonWrapper}>
            <button
              type="button"
              onClick={() => handleBrandNavigate('signup')}
              className="btn btnklub3"
            >
              Apply Now
            </button>
          </div>
        </div>
      </div>
      <div className={`${Styles.sectionWrapper} ${Styles.homeSection2}`}>
        <BrandInfoCard />
      </div>
      <div className={faqstyle.faqSection3} id="faq">
        <div className={faqstyle.section31Wrapper}>
          <h5 className={style.headingStyle}>
            Frequently asked questions
          </h5>
          <form className={faqstyle.searchBarWrapper} onSubmit={onSearchClick}>
            <input
              type="text"
              aria-label="Search"
              placeholder="Ask a question"
              onChange={handleChangeInput}
              className={faqstyle.inputFieldSearch}
            />
            <button
              type="submit"
              className={`btn btnklub3 ${faqstyle.searchButton}`}
              onClick={onSearchClick}
            >
              <img src={SearchIcon} alt="search" />
              <div className={faqstyle.searchBtnText}>Search</div>
            </button>
          </form>
        </div>
        <div className={faqstyle.accordianSectionWrapper}>
          {searchResults.length > 0
            && searchResults.map((element) => (
              <Accordian
                key={element.id}
                id={element.id}
                selectedId={selectedQuestionId}
                title={element.question}
                // eslint-disable-next-line react/no-children-prop
                children={element.answer}
                searchWord={searchWord === prevSearchWord ? searchWord : ''}
                handleClick={setSelectedQuestionId}
              />
            ))}
        </div>
      </div>
      {/* <div className={style.brandSection4}>
        <div className="container">
          <div className={style.section}>
            <div className={style.applyReferSection}>
              <div className={style.applyCardWrapper}>
                <ApplyReferCard
                  icon={apply}
                  link="/brands-contact"
                  heading="Apply"
                  content="Access Klub’s growth capital? Apply now!"
                  buttonText="Apply now"
                />
              </div>
              <div className={style.applyCardMiddleWrapper}>
                <ApplyReferCard
                  icon={groupStock}
                  heading="Refer a Patron"
                  content="Know someone who would love to join Klub’s investor network? "
                  buttonText="Share"
                />
              </div>
              <div className={style.applyCardWrapper}>
                <ApplyReferCard
                  icon={deerStock}
                  heading="Refer a Brand"
                  content="Know a brand that could benefit from the Klub network?"
                  buttonText="Refer Now"
                />
              </div>
            </div>
          </div>
        </div>
      </div> */}

      {/* <div className={style.brandSection5}>
        <div className="container">
          <div className={style.section}>
            <h3>
              BRANDS SPEAK
            </h3>
            <h1 className={style.contentWeb}>
              What our partners say about us
            </h1>
            <h1 className={style.contentMob}>
              What our partners say about us
              <br />
            </h1>
          </div>
        </div>
        <div className={style.carousalWrapper}>
          <Carousal
            name="brandSpeak-brands"
            margin={true}
            autoScroll={false}
          >
            {brandSpeak.map((item) => (
              <BrandSpeakCard
                content={item.content}
                title={item.title}
                logo={item.logo}
                brandImage={item.brandImage}
              />
            ))}
          </Carousal>
        </div>
      </div> */}
    </Layout>
  );
};

export default BrandPage;
